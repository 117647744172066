import { EXT_TICKETMASTER_TICKET_LIMIT } from "@/api/booking/Extensions";
import { Extensions } from "@/api/booking/BookingModels";

/**
 * Retrieves the Ticketmaster ticket limit information from the extension data if present.
 */
export function getTicketmasterTicketLimit(extensions: Extensions | undefined): number | null {
  const limit = extensions?.[EXT_TICKETMASTER_TICKET_LIMIT] || "";
  const parsedLimit = parseInt(limit);
  return limit && !isNaN(parsedLimit) ? parsedLimit : null;
}
