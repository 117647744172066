import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { appModule } from "@/store/modules/moduleApp";
import { codes } from "@/utils/codeConstants";
import { v4 as uuidv4 } from "uuid";
import resellerClient from "@/api/reseller/ResellerClient";
import { Reseller, ResellerContact, ResellerContactType } from "@/api/reseller/ResellerModels";
import { posModule } from "./modulePos";
import { profileModule } from "@/store/modules/moduleProfile";
import { AuditRecordRQ, FeatureFlags, Company } from "@/models/ModelsReseller";
import { httpModule } from "@/store/modules/moduleHttp";
import { isDefinedAndNotEmpty } from "@/utils/stringutils";

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-company",
  namespaced: true,
})
class ModuleCompany extends VuexModule {
  /* Data */
  private loading = false;
  private resellerContacts: any[] = [];
  private company = {} as Company;
  private companyContacts: any[] = [];
  private posContacts: any[] = [];
  private orgCode = "";
  private resellerId = "";
  private reseller: Reseller | null = null;
  private featureFlags: FeatureFlags | null = null;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get ResellerContacts() {
    return this.resellerContacts;
  }

  get CompanyContacts() {
    return this.companyContacts;
  }

  get PosContacts() {
    return this.posContacts;
  }

  get OrgCode(): string {
    return this.orgCode;
  }

  get ResellerId(): string {
    return this.resellerId;
  }

  get Reseller() {
    return this.reseller;
  }

  get IsDisney(): boolean {
    return isDefinedAndNotEmpty(this.reseller?.distributorID);
  }

  get Company(): Company {
    return this.company;
  }

  get FeatureFlags(): FeatureFlags | null {
    return this.featureFlags;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setOrgCode(orgCode: string) {
    this.orgCode = orgCode;
  }

  @Mutation
  setResellerId(resellerId: string) {
    this.resellerId = resellerId;
  }

  @Mutation
  setReseller(reseller: Reseller) {
    this.reseller = reseller;
  }

  @Mutation
  setResellerContacts(resellerContacts: any) {
    this.resellerContacts = resellerContacts;
  }

  @Mutation
  setCompanyContacts(companyContacts: any) {
    this.companyContacts = companyContacts;
  }

  @Mutation
  setPosContacts(posContacts: any) {
    this.posContacts = posContacts;
  }

  @Mutation
  setCompany(company: Company) {
    this.company = company;
  }

  @Mutation
  setFeatureFlags(flags: FeatureFlags | null) {
    this.featureFlags = flags;
  }

  @Action
  configCompany(company: Company) {
    this.setCompany(company);
  }

  @Action
  async getCompanyContacts() {
    try {
      companyModule.startLoading();
      const res = await resellerClient.getContacts(this.ResellerId).toPromise();
      this.setCompanyContacts(res);
      const companyContacts = res.filter((companyContact: ResellerContact) => {
        return companyContact.type === ResellerContactType.RESELLER;
      });
      this.setResellerContacts(companyContacts);
      const posContacts = res.filter((companyContact: ResellerContact) => {
        return companyContact.type === ResellerContactType.POS;
      });
      posContacts.sort(function (a: any, b: any) {
        const start = a.name.toLowerCase().charCodeAt();
        const end = b.name.toLowerCase().charCodeAt();
        return end - start;
      });
      this.setPosContacts(posContacts);
    } catch (e: any) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_LOAD_CONTACTS);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_LOAD_CONTACTS);
      }
      throw e;
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async storeContact(posContact: any) {
    companyModule.startLoading();
    try {
      const actionId = uuidv4();
      const id = posContact.id;
      await resellerClient.storeContact(posContact).toPromise();
      appModule.addRdmAction({
        id: actionId,
        detail: {
          code: codes.COMPANY_CONTACT_UPDATED,
          body: {
            id: id,
          },
        },
      });
      appModule.addMessageSuccess(codes.COMPANY_CONTACT_UPDATED);
      await this.getCompanyContacts();
    } catch (e: any) {
      if (e.status === 404) {
        appModule.addMessageError(codes.COMPANY_ERROR_404_UPDATE_COMPANY);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.COMPANY_ERROR_500_UPDATE_COMPANY);
      }
      throw e;
    } finally {
      companyModule.finishLoading();
    }
  }

  @Action
  async deleteContact(item: any) {
    await resellerClient
      .deleteContact(item.resellerID, item.id)
      .toPromise()
      .then(() => this.getCompanyContacts())
      .then(() => {
        appModule.addRdmAction({
          id: uuidv4(),
          detail: {
            code: codes.COMPANY_CONTACT_UPDATED,
            body: {
              id: item.id,
            },
          },
        });
        appModule.addMessageSuccess(codes.COMPANY_CONTACT_DELETED);
      });
  }

  @Action
  async setResellerInfo() {
    return Promise.resolve(companyModule.startLoading())
      .then(() => {
        return resellerClient
          .getResellers()
          .toPromise()
          .then((res) => {
            if (res && res.length > 0) {
              this.setResellerId(res[0]?.id);
              this.setReseller(res[0]);
              const code = res[0].distributorID;
              posModule.setDistributorCode(code);

              profileModule.setResellerId(res[0]?.id);
              posModule.setResellerId(res[0]?.id);
              const military = res[0]?.config?.military || false;
              const paymentEnabled = res[0]?.config?.paymentsEnabled || false;
              profileModule.setIsMilitary(military);
              profileModule.setPaymentEnabled(paymentEnabled);

              const channelID = res[0].channelID;
              posModule.setChannelID(channelID);
              return;
            }

            return Promise.reject();
          })
          .catch(() => appModule.addMessageErr(codes.COMPANY_ERROR_404_LOAD_RESELLER))
          .then(() => this.getCompanyContacts());
      })
      .finally(() => companyModule.finishLoading());
  }

  @Action
  async auditRecord(record: AuditRecordRQ) {
    await resellerClient
      .createAuditLog(record)
      .toPromise()
      .catch(() => {
        httpModule.logout();
      });
  }

  @Action
  async loadFeatureFlags() {
    await resellerClient
      .checkFeatureFlags()
      .toPromise()
      .then((flags: FeatureFlags) => {
        this.setFeatureFlags(flags);
      })
      .catch(() => {
        this.setFeatureFlags(null);
      });
  }

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
  }
}

export const companyModule = getModule(ModuleCompany, store);
