import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { appModule } from "@/store/modules/moduleApp";
import { codes } from "@/utils/codeConstants";
import { switchMap } from "rxjs/operators";
import backplaneClient from "@/api/backplane/BackplaneClient";
import { userModule } from "@/store/modules/moduleUser";
import { Account as UserProfile, ApiLoginRQ, QueryForgotPassword, QueryListUsers } from "@/api/backplane/BackplaneModels";
import { constants } from "@/utils/constants";
import { profileModule } from "@/store/modules/moduleProfile";
import router from "@/router";
import _ from "lodash";
import { ActionButton } from "@/models";
import { v4 as uuidv4 } from "uuid";
import { definitionRoles, sortAlpha } from "@/utils/helpers";
import { companyModule } from "./moduleCompany";
import { ResellerUserConfig } from "@/api/reseller/ResellerModels";
import resellerClient from "@/api/reseller/ResellerClient";
import { GenerateBookingsReportsParams } from "@/api/reports/ReportsModels";
import reportsClient from "@/api/reports/ReportsClient";
import stripeClient from "@/api/stripe/StripeClient";
import { reportsModule } from "./moduleReports";
import { posModule } from "@/store/modules/modulePos";
import { Logger } from "@/api/logging/logger";
import { CheckoutSessionRQ, CheckoutSessionRS, CheckoutSessionStatusRS, refundPayload, SessionUpdatePayload } from "@/api/stripe/stripe.models";
import { stripeModule } from "@/store/modules/stripeModule";

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-http",
  namespaced: true,
})
class ModuleHttp extends VuexModule {
  private logger = new Logger();
  @Action
  async login(credentials: ApiLoginRQ) {
    const user = await backplaneClient
      .login(credentials)
      .pipe(switchMap(() => backplaneClient.me()))
      .toPromise();

    profileModule.setProfile(user);
    await this.getContacts();
    const isMilitary = profileModule.IsMilitary;
    if (isMilitary) {
      await router.push({ name: constants.routes.MILITARY });
      return;
    }
    await router.push({ name: constants.routes.BOOKINGS });
  }

  @Action
  async logout() {
    try {
      await backplaneClient.logout().toPromise();
    } finally {
      // clear state in all modules which registered a root 'clearState' action
      await appModule.clearState();
      await router.push({ name: constants.routes.LOGIN, query: { redirect: router.currentRoute.fullPath } });

      location.reload(); // todo investigate if page refresh is still needed
    }
  }

  @Action
  async getProfile() {
    const profile = await backplaneClient.me().toPromise();
    profileModule.setProfile(profile);
  }

  @Action
  async getContacts() {
    if (profileModule.Profile && profileModule.Profile?.org_code) {
      companyModule.setOrgCode(profileModule.Profile?.org_code);
      await companyModule.setResellerInfo();
    }
  }

  @Action
  async forgotPassword(emailDetails: QueryForgotPassword) {
    try {
      await backplaneClient.forgotPassword(emailDetails);
    } catch (e) {
      this.logger.error("error with forgot password", { error: e });
    }
  }

  @Action
  async getUsers(userStatus?: number) {
    await Promise.resolve(userModule.startLoading())
      .then(async () => {
        const query: QueryListUsers = {};
        switch (userStatus) {
          case 1:
            query.active = true;
            break;
          case 2:
            query.inactive = true;
            break;
          default:
            break;
        }
        const resellerID = companyModule.ResellerId;
        const userConfigs = await resellerClient.getUserConfigs(resellerID).toPromise();
        const res = await backplaneClient.listUsers(query).toPromise();
        const accounts = res.accounts;
        const posCodes = _.map(posModule.Pos, "code") as Array<string>;
        return { userConfigs, accounts, posCodes };
      })
      .then(({ userConfigs, accounts, posCodes }) => {
        if (accounts) {
          const account = accounts
            .filter(
              (user) =>
                !user.email ||
                (!user.email.match("devops.*@redeam.com") && !user.email.match("wps.*@redeam.com") && !user.email.match("secaudit.*@redeam.com")),
            )
            .filter((user: any) => user && Array.isArray(user.groups) && user.groups.findIndex((group: any) => definitionRoles.includes(group.name)) > -1)
            .map((user) => {
              if (userConfigs.length !== 0) {
                const config = userConfigs.find((config) => config.email.toLowerCase() === user.email?.toLowerCase());
                if (config && config.pointsOfSale) {
                  const tempUserPos = _.compact(config.pointsOfSale).toString();
                  const userPos = tempUserPos.replace(/,/g, ", ");
                  user.pos = userPos;
                }
              }
              return user;
            })
            .filter((user) => {
              const hasPos = Array.isArray(posModule.PosList) && posModule.PosList.length > 0;
              const isCompanyManager = profileModule.ISManager || profileModule.IsSuperAdmin;
              // ignore filter if there are no POS
              if (!hasPos || isCompanyManager) {
                return user;
              }
              const groups = user.groups;
              let isSuperAmin = false;
              if (profileModule.Profile && profileModule.Profile.id && user.id === profileModule.Profile.id) {
                return user;
              }
              if (groups && Array.isArray(groups)) {
                groups.forEach((g) => {
                  if (g.name === "RESELLER_PORTAL_SUPER_ADMIN" || g.name === "RESELLER_PORTAL_LOCATION_SUPER_ADMIN") {
                    isSuperAmin = true;
                  }
                });
                if (!isSuperAmin && (profileModule.ISLocationAdmin || profileModule.IsLocationSuperAdmin)) {
                  const posCodeCount = posCodes.length;
                  if (user.pos) {
                    const userPos = user.pos as string;
                    const posList = userPos.split(", ");
                    if (posCodeCount > 0 && Array.isArray(posList)) {
                      const configCount = posList.length;
                      const filteredCodes = _.difference(posList, posCodes);
                      if (configCount > filteredCodes.length) {
                        return user;
                      }
                    }
                  }
                }
              }
              return false;
            });
          return sortAlpha(account, "given_name");
        } else {
          return [];
        }
      })
      .then((filterdUsers) => userModule.setUsers(filterdUsers))
      .catch((e) => {
        if (e.status === 500) {
          appModule.addMessageError(codes.USER_ERROR_500_LOAD_USER);
        }
      })
      .finally(() => userModule.finishLoading());
  }

  @Action
  async createUser(user: UserProfile) {
    try {
      const message = `Added "${user.email || "user"}"`;
      userModule.startLoading();
      const res = await backplaneClient.createUser({ account: user }).toPromise();
      userModule.addUser(res.account);
      const posReq: ResellerUserConfig = {
        id: res.account?.id || "",
        resellerID: companyModule.ResellerId,
        email: user.email || "",
        pointsOfSale: user.pos || [],
      };

      this.posAddEdit(posReq);
      if (res.account?.id && res.account.id.length > 0) {
        const id = res.account.id;
        const actionId = uuidv4();
        appModule.addRdmAction({
          id: actionId,
          detail: {
            code: codes.USER_CREATED,
            button: ActionButton.VIEW,
            body: {
              id: id,
            },
          },
        });
      }
      appModule.addMessageSuccess([codes.USER_CREATED, message]);
    } catch (e: any) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_CREATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async updateUserProfile(user: UserProfile) {
    try {
      userModule.startLoading();
      const userId = user?.id?.toString();
      await backplaneClient.updateUser(`${userId}`, { account: user }).toPromise();
      userModule.editUser(user);
    } catch (e: any) {
      if (e.status === 404) {
        appModule.addMessageError(codes.USER_ERROR_404_UPDATE_USER);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
      throw e;
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async updateUser(user: UserProfile) {
    try {
      userModule.startLoading();
      await backplaneClient.updateUser(userModule.Users[userModule.UserToEdit.i].id, { account: user }).toPromise();
      const userGroups = user.groups;
      user.groups = userModule.Users[userModule.UserToEdit.i].groups;
      userModule.editUser(user);
      user.groups = userGroups;
      await backplaneClient
        .updateUserRole(userModule.Users[userModule.UserToEdit.i].id, {
          groups: user?.groups?.map((v) => v.id) as string[],
        })
        .toPromise();

      const posReq: ResellerUserConfig = {
        id: user.id || "",
        resellerID: companyModule.ResellerId,
        email: user.email || "",
        pointsOfSale: user.pos,
      };
      this.posAddEdit(posReq);
      userModule.editUser(user);
      await companyModule.setResellerInfo();
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e: any) {
      if (e.status === 404) {
        appModule.addMessageError(codes.USER_ERROR_404_UPDATE_USER);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
      throw e;
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async posAddEdit(userConfig: ResellerUserConfig) {
    await Promise.resolve(userModule.startLoading())
      .then(() => resellerClient.storeUserConfig(userConfig).toPromise())
      .catch((e) => {
        if (e.status === 404) {
          appModule.addMessageError(codes.POS_ERROR_404_UPDATE_POS);
        }
        if (e.status === 500) {
          appModule.addMessageError(codes.POS_ERROR_500_UPDATE_POS);
        }
        throw e;
      })
      .finally(() => userModule.finishLoading());
  }

  @Action
  async deactivateUser() {
    try {
      userModule.startLoading();
      const id = userModule.Users[userModule.UserToEdit.i].id;
      const actionId = uuidv4();
      await backplaneClient.deactivateUser(id).toPromise();
      userModule.updateUserStatus(2);
      appModule.addRdmAction({
        id: actionId,
        detail: {
          code: codes.USER_DEACTIVATED,
          //button: ActionButton.UNDO,
          body: {
            id: id,
          },
        },
      });
      appModule.addMessageSuccess(codes.USER_DEACTIVATED);
    } catch (e: any) {
      userModule.dropUser();
      if (e.status === 404) {
        appModule.addMessageError(codes.USER_ERROR_404_DELETE_USER);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_DEACTIVATED_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async activateUser() {
    try {
      userModule.startLoading();
      const id = userModule.Users[userModule.UserToEdit.i].id;
      const actionId = uuidv4();
      await backplaneClient.activateUser(id).toPromise();
      userModule.updateUserStatus(1);
      appModule.addRdmAction({
        id: actionId,
        detail: {
          code: codes.USER_ACTIVATED,
          //button: ActionButton.VIEW,
          body: {
            id: id,
          },
        },
      });
      appModule.addMessageSuccess(codes.USER_ACTIVATED);
    } catch (e: any) {
      userModule.dropUser();
      if (e.status === 404) {
        appModule.addMessageError(codes.USER_ERROR_404_UPDATE_USER);
      }
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_ACTIVATED_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async updateSelectedUserGroups(selectedGroups: any[]) {
    try {
      userModule.startLoading();
      for (const user of userModule.SelectedUsers) {
        await userModule.chooseUserToUpdate(user.i);
        const nonKnownRoles = userModule.ExistingGroups || [];
        if (userModule.BulkAction === constants.ROLE_ADD) {
          user.groups = nonKnownRoles.concat(selectedGroups.filter((g) => nonKnownRoles.findIndex((n) => n.id === g.id) === -1));
        } else if (userModule.BulkAction === constants.ROLE_REMOVE) {
          user.groups = nonKnownRoles.filter((n) => selectedGroups.findIndex((g) => g.id === n.id) === -1);
        }
        await this.updateUser(user);
      }
      userModule.setSelectedUsers([]);
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e: any) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async deactivateSelectedUsers() {
    try {
      userModule.startLoading();
      for (const user of userModule.SelectedUsers) {
        await userModule.chooseUserIndexToEdit(user.id);
        await this.deactivateUser();
      }
      userModule.setSelectedUsers([]);
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e: any) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async activateSelectedUsers() {
    try {
      userModule.startLoading();
      for (const user of userModule.SelectedUsers) {
        await userModule.chooseUserIndexToEdit(user.id);
        await this.activateUser();
      }
      userModule.setSelectedUsers([]);
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e: any) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async toggleUserStatus() {
    try {
      userModule.startLoading();
      const users: any[] = [];
      Object.assign(users, userModule.Users);
      userModule.setUsers([]);

      if (users[userModule.UserToEdit] && users[userModule.UserToEdit.i].deactivated_at) {
        delete users[userModule.UserToEdit.i].deactivated_at;
      } else if (users[userModule.UserToEdit.i] && !users[userModule.UserToEdit.i].deactivated_at) {
        users[userModule.UserToEdit.i].deactivated_at = Date.now();
      }

      userModule.setUsers(users);
      userModule.chooseUserToEdit(-1);
      appModule.addMessageSuccess(codes.USER_UPDATED);
    } catch (e: any) {
      if (e.status === 500) {
        appModule.addMessageError(codes.USER_ERROR_500_UPDATE_USER);
      }
    } finally {
      userModule.finishLoading();
    }
  }

  @Action
  async generateBookingsReport(params: GenerateBookingsReportsParams) {
    reportsModule.startLoading();
    await reportsClient
      .generateBookingsReport(params)
      .then((data) => reportsModule.setReportData(data))
      .catch((e) => {
        if (e.status === 500) {
          appModule.addMessageError(codes.REPORTS_ERROR_500_SEARCH);
        }

        if (e.status === 400) {
          appModule.addMessageError(codes.REPORTS_ERROR_400_SEARCH);
        }
      })
      .finally(() => reportsModule.finishLoading());
  }

  /* Stripe calls */
  @Action
  async createCheckoutSession(payload: CheckoutSessionRQ) {
    await stripeClient
      .createCheckoutSession(payload)
      .toPromise()
      .then((data: CheckoutSessionRS) => {
        stripeModule.setClientSecret(data.clientSecret);
        stripeModule.setSessionID(data.sessionId);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {});
  }
  @Action
  async retrieveCheckoutSession(sessionID: string) {
    await stripeClient
      .retrieveCheckoutSession(sessionID)
      .toPromise()
      .then((data: CheckoutSessionStatusRS) => {
        stripeModule.setSessionStatus(data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {});
  }
  @Action
  async updateCheckoutSession(payload: SessionUpdatePayload) {
    await stripeClient
      .updateCheckoutSession(payload)
      .toPromise()
      .then((data: CheckoutSessionStatusRS) => {
        stripeModule.setSessionStatus(data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {});
  }
  @Action
  async refundPayment(payload: refundPayload) {
    await stripeClient
      .refundPayment(payload)
      .toPromise()
      .then((data: CheckoutSessionStatusRS) => {
        stripeModule.setSessionStatus(data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {});
  }
}

export const httpModule = getModule(ModuleHttp, store);
