import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { Account as UserProfile } from "@/api/backplane/BackplaneModels";
import { constants, Role } from "@/utils/constants";
import Vue from "vue";

export function IsRole(me: UserProfile | null, role: Role): boolean {
  const userRoles = me?.groups;
  return !!(userRoles && userRoles.findIndex((group: any) => role.includes(group.name)) > -1);
}

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-profile",
  namespaced: true,
})
class ModuleProfile extends VuexModule {
  /* Data */
  private loading = false;
  private profile: UserProfile | null = null;
  private resellerId = "";
  private posLoading = false;
  private isMilitary = false;
  private paymentEnabled = false;

  /* Getters */
  get Loading(): boolean {
    return this.loading;
  }

  get PaymentEnabled(): boolean {
    return this.paymentEnabled;
  }

  get PosLoading(): boolean {
    return this.posLoading;
  }

  get Profile(): UserProfile | null {
    return this.profile;
  }

  get IsMilitary(): boolean {
    return this.isMilitary;
  }

  get IsSuperAdmin(): boolean {
    return IsRole(this.profile, constants.definitionRoles.RESELLER_PORTAL_SUPER_ADMIN);
  }

  get IsLocationSuperAdmin(): boolean {
    return IsRole(this.profile, constants.definitionRoles.RESELLER_PORTAL_LOCATION_SUPER_ADMIN);
  }

  get ISLocationAdmin(): boolean {
    return IsRole(this.profile, constants.definitionRoles.RESELLER_PORTAL_LOCATION_ADMIN) || this.IsSuperAdmin;
  }

  get ISManager(): boolean {
    return IsRole(this.profile, constants.definitionRoles.RESELLER_PORTAL_MANAGER) || this.IsSuperAdmin;
  }

  get ISAgent(): boolean {
    return IsRole(this.profile, constants.definitionRoles.RESELLER_PORTAL_AGENT);
  }

  get HasReports(): boolean {
    return IsRole(this.profile, constants.definitionRoles.RESELLER_PORTAL_REPORTS);
  }

  get ResellerId(): string {
    return this.resellerId;
  }

  /* Setters */
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setPosLoading(posLoading: boolean) {
    this.posLoading = posLoading;
  }

  @Mutation
  setIsMilitary(isMilitary: boolean) {
    this.isMilitary = isMilitary;
  }

  @Mutation
  setPaymentEnabled(val: boolean) {
    this.paymentEnabled = val;
  }

  @Mutation
  setProfile(profile: UserProfile | null) {
    this.profile = profile;
  }

  @Mutation
  setResellerId(resellerId: string) {
    this.resellerId = resellerId;
  }

  /* Actions */

  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  finishLoading() {
    this.setLoading(true);
  }

  @Action
  clearProfile() {
    this.setProfile(null);
    Vue.$cookies.remove(constants.ACCESS_TOKEN);
    Vue.$cookies.remove(constants.REFRESH_TOKEN);
  }

  @Action
  clearState() {
    this.setLoading(false);
    this.setIsMilitary(false);
    this.clearProfile();
  }
}

export const profileModule = getModule(ModuleProfile, store);
