import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { CheckoutSessionStatusRS, SessionUpdatePayload } from "@/api/stripe/stripe.models";

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-stripe",
  namespaced: true,
})
class ModuleStripe extends VuexModule {
  private clientSecret = "";
  private sessionID = "";
  private sessionStatus = {} as CheckoutSessionStatusRS;
  private checkout = null;
  private sessionUpdateData = {} as SessionUpdatePayload;
  private sessionPaymentIntent = "";

  //todo create an interface for checkout instance RE-8144
  get Checkout(): any {
    return this.checkout;
  }

  get SessionUpdateData(): SessionUpdatePayload {
    return this.sessionUpdateData;
  }

  get SessionStatus(): CheckoutSessionStatusRS {
    return this.sessionStatus;
  }

  get ClientSecret(): string {
    return this.clientSecret;
  }

  get SessionID(): string {
    return this.sessionID;
  }

  get SessionPaymentIntent(): string {
    return this.sessionPaymentIntent;
  }

  @Mutation
  setClientSecret(clientSecret: string) {
    this.clientSecret = clientSecret;
  }
  @Mutation
  setSessionID(sessionID: string) {
    this.sessionID = sessionID;
  }
  @Mutation
  setSessionStatus(status: CheckoutSessionStatusRS) {
    this.sessionStatus = status;
    this.sessionPaymentIntent = status.session?.payment_intent;
  }
  @Mutation
  setCheckout(checkout: any) {
    this.checkout = checkout;
  }
  @Mutation
  setSessionUpdateData(data: SessionUpdatePayload) {
    this.sessionUpdateData = data;
  }
}

export const stripeModule = getModule(ModuleStripe, store);
