import axios, { AxiosInstance } from "axios";
import { from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { config } from "@/utils/config";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { handleErrs } from "@/api/ErrHandlers";
import { MeErrHandlers } from "@/api/userErrHandlers/ErrHandlers";
import { CheckoutSessionRQ, CheckoutSessionRS, CheckoutSessionStatusRS, refundPayload, SessionUpdatePayload } from "@/api/stripe/stripe.models";

const xsrfCookieName = "XSRF-TOKEN";

export class StripeClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.StripeUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(StripeClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    if (err.response?.status === 401) {
      return StripeClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data;
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public createCheckoutSession(payload: CheckoutSessionRQ, errs?: MeErrHandlers): Observable<CheckoutSessionRS> {
    return from(this.httpClient.post<any>(`/checkout-create-session/${payload.amount}/${payload.currency}/${payload.productName}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public updateCheckoutSession(payload: SessionUpdatePayload, errs?: MeErrHandlers): Observable<any> {
    return from(this.httpClient.post<any>(`/checkout-update-session/${payload.sessionID}`, JSON.stringify(payload.metadata))).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public retrieveCheckoutSession(sessionID: string, errs?: MeErrHandlers): Observable<CheckoutSessionStatusRS> {
    return from(this.httpClient.get<any>(`/checkout-retrieve-session/${sessionID}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
  public refundPayment(payload: refundPayload, errs?: MeErrHandlers): Observable<CheckoutSessionStatusRS> {
    return from(this.httpClient.post<any>(`/refund-payment/${payload.payment_intent}/${payload.amount}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
}

const stripeClient = new StripeClient();

export default stripeClient;
